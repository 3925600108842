import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import SchemaOrg from './schema-org';
import defaultMetaImage from '../../images/metaImage.png';

function SEO({
    siteMetadata: seo,
    postData,
    metaImage,
    isBlogPost,
    frontmatter: postMeta = postData.childMarkdownRemark?.frontmatter || {},
    title = postMeta?.title || seo.title,
    description = postMeta?.plainTextDescription ||
        postMeta?.description ||
        seo.description,
    image = `${ seo.canonicalUrl }${ metaImage || defaultMetaImage }`,
    url = postMeta.slug
        ? `${ seo.canonicalUrl }${ postMeta?.slug }`
        : seo.canonicalUrl,
    datePublished = isBlogPost
        ? postMeta?.datePublished
        : new Date().toISOString(),
    lang,
    pageTitle,
}) {
    return (
        <>
            <Helmet
                htmlAttributes={{
                    lang,
                }}
                title={ title }
                titleTemplate={ `%s | ${ pageTitle }` }
                meta= {[
                    {
                        name: `description`,
                        content: description,
                    },
                    {
                        name: `image`,
                        content: image,
                    },
                    {
                        name: `og:url`,
                        content: url,
                    },
                    {
                        name: `og:title`,
                        content: title,
                    },
                    {
                        name: `og:description`,
                        content: description,
                    },
                    {
                        name: `og:image`,
                        content: image,
                    },
                    {
                        property: `og:type`,
                        content: isBlogPost ? `article` : `website`,
                    },
                    {
                        name: `twitter:card`,
                        content: `summary_large_image`,
                    },
                    {
                        name: `twitter:creator`,
                        content: seo.social.twitter,
                    },
                    {
                        name: `twitter:title`,
                        content: title,
                    },
                    {
                        name: `twitter:description`,
                        content: description,
                    },
                    {
                        name: `twitter:image`,
                        content: image,
                    },
                ] 
                    .concat(
                        seo?.keywords.length > 0
                        ? {
                            name: `keywords`,
                            content: seo.keywords.join(`, `),
                        }
                        : []
                    )}
            />
            <SchemaOrg
                isBlogPost={ isBlogPost }
                url={ url }
                title={ title }
                image={ image }
                description={ description }
                datePublished={ datePublished }
                canonicalUrl={ seo.canonicalUrl }
                author={ seo.author }
                organization={ seo.organization }
                defaultTitle={ seo.title }
            />
        </>
    );
}

function SEOWithQuery(props) {
    const {
        site: {siteMetadata},
    } = useStaticQuery(graphql`
        {
            site {
                siteMetadata {
                    title
                    description
                    keywords
                    canonicalUrl
                    image
                    author {
                        name
                    }
                    organization {
                        name
                        url
                        logo
                    }
                    social {
                        twitter
                    }
                }
            }
        }
    `);
    return <SEO siteMetadata={ siteMetadata } { ...props } />;
}

SEOWithQuery.propTypes = {
    pageTitle: PropTypes.string,
    lang: PropTypes.string,
    isBlogPost: PropTypes.bool,
    postData: PropTypes.shape({
        childMarkdownRemark: PropTypes.shape({
            frontmatter: PropTypes.any,
            excerpt: PropTypes.any,
        }),
    }),
    metaImage: PropTypes.string,
};

SEOWithQuery.defaultProps = {
    pageTitle: `Home`,
    lang: `en`,
    isBlogPost: false,
    postData: { childMarkdownRemark: {} },
    metaImage: null,
};

export default SEOWithQuery;